/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Login.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsCondition from "@/views/TermsCondition.vue";
import NotFound from "@/views/NotFound.vue";
import store from "@/store";
import { useLoading } from 'vue-loading-overlay'

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return { name: "Login" };
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { title: "Login | Mobooking" },
  },
  {
    path: "/form-pengajuan-merchant",
    name: "FormPengajuanMerchant",
    component: () => import("@/views/FormPengajuanMerchant.vue"),
    meta: { title: "Form Pengajuan Merchant | Mobooking" },
  },
  {
    path: "/form-tes-lab",
    name: "FormTesLab",
    component: () => import("@/views/FormTesLab.vue"),
    meta: { title: "Form Tes Lab | Mobooking" },
  },
  {
    path: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { title: "Dashboard | Mobooking" },
      },
    ],
  },
  {
    path: "/kegiatan",
    name: "Kegiatan",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "kumpul-bersama",
        name: "KumpulBersama",
        component: () => import("@/views/kegiatan/KumpulBersama.vue"),
        meta: { title: "Kumpul Bersama | Mobooking" },
      },
      {
        path: "berlangsung",
        name: "KegiatanBerlangsung",
        component: () => import("@/views/kegiatan/Berlangsung.vue"),
        meta: { title: "Kegiatan Berlangsung | Mobooking" },
      },
      {
        path: "riwayat",
        name: "RiwayatKegiatan",
        component: () => import("@/views/kegiatan/Riwayat.vue"),
        meta: { title: "Riwayat Kegiatan | Mobooking" },
      },
    ],
  },
  {
    path: "/merchant",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "summary",
        name: "MerchantSummary",
        component: () => import("@/views/merchant/Summary.vue"),
        meta: { title: "Merchant Summary | Mobooking" },
      },
      {
        path: "menunggu-persetujuan",
        name: "MerchantMenungguPersetujuan",
        component: () => import("@/views/merchant/MenungguPersetujuan.vue"),
        meta: { title: "Menunggu Persetujuan | Mobooking" },
      },
      {
        path: "aktif",
        name: "MerchantAktif",
        component: () => import("@/views/merchant/Aktif.vue"),
        meta: { title: "Merchant Aktif | Mobooking" },
      },
      {
        path: "aktif/:id",
        name: "MerchantAktifDetail",
        component: () => import("@/views/merchant/AktifDetail.vue"),
        meta: { title: "Merchant Aktif | Mobooking" },
      },
      {
        path: "aktif/:id/event/:eventid",
        name: "MerchantAktifDetailEvent",
        component: () => import("@/views/merchant/AktifDetailEvent.vue"),
        meta: { title: "Merchant Aktif Event Detail | Mobooking" },
      },
      {
        path: "menunggu-respon-selanjutnya",
        name: "MerchantMenungguRespon",
        component: () => import("@/views/merchant/MenungguRespon.vue"),
        meta: { title: "Menunggu Respon Selanjutnya | Mobooking" },
      },
    ],
  },
  {
    path: "/event",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "summary",
        name: "EventSummary",
        component: () => import("@/views/event/Summary.vue"),
        meta: { title: "Event Summary | Mobooking" },
      },
      {
        path: "menunggu-persetujuan",
        name: "MenungguPersetujuanEvent",
        component: () => import("@/views/event/MenungguPersetujuan.vue"),
        meta: { title: "Menunggu Persetujuan Event | Mobooking" },
      },
      {
        path: "berlangsung",
        name: "EventBerlangsung",
        component: () => import("@/views/event/Berlangsung.vue"),
        meta: { title: "Event Berlangsung | Mobooking" },
      },
      {
        path: "ditolak",
        name: "EventReject",
        component: () => import("@/views/event/Rejected.vue"),
        meta: { title: "Event Ditolak | Mobooking" },
      }
    ],
  },
  {
    path: "/refund",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "pengajuan",
        name: "RefundPengajuan",
        component: () => import("@/views/refund/Pengajuan.vue"),
        meta: { title: "Pengajuan Refund | Mobooking" },
      },
      {
        path: "pembayaran",
        name: "RefundPembayaran",
        component: () => import("@/views/refund/Pembayaran.vue"),
        meta: { title: "Pembayaran Refund | Mobooking" },
      }
    ],
  },
  {
    path: "/sebaran-covid",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "summary",
        name: "SebaranCovidSummary",
        component: () => import("@/views/sebaran-covid/Summary.vue"),
        meta: { title: "Sebaran COVID-19 Summary | Mobooking" },
      },
      {
        path: "penanganan-perwilayah",
        name: "SebaranCovidPenayangan",
        component: () => import("@/views/sebaran-covid/Penayangan.vue"),
        meta: { title: "Sebaran COVID-19 Penanganan Per Wilayah | Mobooking" },
      },
    ],
  },
  {
    path: "/payout",
    component: () => import("@/layout/Layout.vue"),
    children: [
      // {
      //   path: "pengajuan-event-daftar-transfer",
      //   name: "PayoutDaftarTransfer",
      //   component: () => import("@/views/payout/DaftarTransfer.vue"),
      //   meta: { title: "Daftar Transfer Payout | Mobooking" },
      // },
      // {
      //   path: "pengajuan-event",
      //   name: "PayoutPengajuanEvent",
      //   component: () => import("@/views/payout/PengajuanEvent.vue"),
      //   meta: { title: "Pengajuan Event Payout | Mobooking" },
      // },
      {
        path: "pengajuan-payout",
        name: "PengajuanPayout",
        component: () => import("@/views/payout/PengajuanPayout.vue"),
        meta: { title: "Pengajuan Payout | Mobooking" },
      },
      {
        path: "daftar-rekening",
        name: "DaftarRekening",
        component: () => import("@/views/payout/DaftarRekening.vue"),
        meta: { title: "Daftar Rekening | Mobooking" },
      },
    ],
  },
  {
    path: "/berita",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "",
        name: "Berita",
        component: () => import("@/views/berita/index.vue"),
        meta: { title: "Berita | Mobooking" },
      },
      {
        path: "add",
        name: "AddBerita",
        component: () => import("@/views/berita/add.vue"),
        meta: { title: "Tambah Berita | Mobooking" },
      },
      {
        path: "edit/:id",
        name: "EditBerita",
        component: () => import("@/views/berita/edit.vue"),
        meta: { title: "Edit Berita | Mobooking" },
      },
    ],
  },
  {
    path: "/banner",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "",
        name: "Banner",
        component: () => import("@/views/banner/index.vue"),
        meta: { title: "Banner | Mobooking" },
      },
      {
        path: "add",
        name: "AddBanner",
        component: () => import("@/views/banner/add.vue"),
        meta: { title: "Tambah Banner | Mobooking" },
      },
      {
        path: "edit/:id",
        name: "EditBanner",
        component: () => import("@/views/banner/edit.vue"),
        meta: { title: "Edit Banner | Mobooking" },
      },
    ],
  },
  {
    path: "/pengaturan",
    name: "Pengaturan",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "kelola-admin",
        name: "KelolaAdmin",
        component: () => import("@/views/pengaturan/KelolaAdmin.vue"),
        meta: { title: "Kelola Admin | Mobooking" },
      },
      {
        path: "kelola-role",
        name: "KelolaRole",
        component: () => import("@/views/pengaturan/KelolaRole.vue"),
        meta: { title: "Kelola Role | Mobooking" },
      },
      {
        path: "kelola-menu",
        name: "KelolaMenu",
        component: () => import("@/views/pengaturan/KelolaMenu.vue"),
        meta: { title: "Kelola Menu | Mobooking" },
      },
      {
        path: "kelola-jenis-usaha",
        name: "PengaturanJenisUsaha",
        component: () => import("@/views/pengaturan/jenis-usaha/index.vue"),
        meta: { title: "Kelola Jenis Usaha | Mobooking" }
      },
      {
        path: "kelola-jenis-usaha/add",
        name: "AddPengaturanJenisUsaha",
        component: () => import("@/views/pengaturan/jenis-usaha/add.vue"),
        meta: { title: "Tambah Jenis Usaha | Mobooking" },
      },
      {
        path: "kelola-jenis-usaha/edit/:id",
        name: "EditPengaturanJenisUsaha",
        component: () => import("@/views/pengaturan/jenis-usaha/edit.vue"),
        meta: { title: "Edit Jenis Usaha | Mobooking" },
      },
      {
        path: "kelola-merchant",
        name: "KelolaMerchant",
        component: () => import("@/views/pengaturan/kelola-merchant/index.vue"),
        meta: { title: "Kelola Merchant | Mobooking" },
      },
      {
        path: "kelola-merchant/setup/:id",
        name: "setupLayananMerchant",
        component: () => import("@/views/pengaturan/kelola-merchant/setupLayanan.vue"),
        meta: { title: "Setup Layanan | Mobooking" }
      },
      {
        path: "kelola-section-app",
        component: () =>
          import(
            "@/views/pengaturan/KelolaSectionApp/KelolaSectionAppLayout.vue"
          ),
        children: [
          {
            path: "",
            name: "KelolaSectionApp",
            component: () =>
              import(
                "@/views/pengaturan/KelolaSectionApp/KelolaSectionApp.vue"
              ),
            meta: { title: "Kelola Section App | Mobooking" },
          },
          {
            path: "tambah-baru",
            name: "TambahSectionApp",
            component: () =>
              import(
                "@/views/pengaturan/KelolaSectionApp/TambahSectionApp.vue"
              ),
            meta: { title: "Tambah Section App | Mobooking" },
          },
          {
            path: "edit/:id",
            name: "EditSectionApp",
            props: true,
            component: () =>
              import("@/views/pengaturan/KelolaSectionApp/EditSectionApp.vue"),
            meta: { title: "Edit Section App | Mobooking" },
          },
        ],
      },
    ],
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
    meta: { title: "Privacy Policy | Mobooking" },
  },
  {
    path: "/terms-condition",
    name: "TermsCondition",
    component: TermsCondition,
    meta: { title: "Terms and Condition | Mobooking" },
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
    meta: { title: "Not Found | Mobooking" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Loading Overlay
let loader = null;
function hideLoader() {
  // destroy previous
  if (loader) {
    loader.hide();
    loader = null;
  }
}

function showLoader() {
  hideLoader();
  loader = $loading.show({
    loader: 'dots',
    backgroundColor: '#c0bcbc',
    color: '#3f69eb',
    opacity: 0.3,
    zIndex: 999,
  })
}

const $loading = useLoading()

router.beforeEach(async (to, from, next) => {
  showLoader()

  // redirect to login page if user is not logged in and trying to access a restricted page
  const publicPages = ["/privacy-policy", "/terms-condition", "/login", "/register", "/form-pengajuan-merchant", "/"];

  // if not path public
  const authRequired = !publicPages.includes(to.path);

  const hasAccessToken = store.state.auth.access_token;

  const currentRole = hasAccessToken !== null && store.state.auth.user_active_profile.role.name;

  const hasAccessMenu =
    hasAccessToken !== null &&
    (await store.state.auth.user_active_profile.menu.filter((menu) => {
      return to.path.replaceAll("-", "_").includes(menu.code);
    }));

  // console.log(store.state.auth.user_active_profile.menu)
  // console.log(hasAccessToken)

  document.title = to.meta.title || "Mobooking";

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
    // console.log(hasAccessMenu.length < 1);
  }, 100);

  if (authRequired && hasAccessToken === null) {
    next("/login");
  } else if (!authRequired && hasAccessToken !== null) {
    next("/dashboard");
  } else if (hasAccessMenu.length < 1 && currentRole == "Lab") {
    next("/form-tes-lab");
  } else {
    if (hasAccessMenu.length < 1) {
      next(from.fullPath);
    } else {
      next();
    }
  }
});

router.afterEach(() => {
  hideLoader()
});

router.onError((e) => {
  console.log('router.onError :',e)
});
router.resolve(() => {
  console.log('router.resolve :')
})

export default router;
