import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        accountbank:{
            loading: false,
            list: [],
            meta: {},
            error: '',
        },
        createupdate: {
            loading: false,
            data: null,
            message: '',
            error: '',
        },
        delbyid: {
            loading: false,
            data: null,
            error: '',
        },
    },
    mutations: {
        _setListAccountBank(state, payload) {
            state.accountbank.loading = payload.loading
            state.accountbank.list = payload.list
            state.accountbank.meta = payload.meta
            if (payload.error != undefined) {
                state.accountbank.error = payload.error
            }
        },
        setCreateUpdate(state, payload) {
            state.createupdate.loading = payload.loading
            state.createupdate.data = payload.data
            if (payload.error != undefined) {
                state.createupdate.error = payload.error
            }
        },
        setDelete(state, payload) {
            state.delbyid.loading = payload.loading
            state.delbyid.data = payload.data
            if (payload.error != undefined) {
                state.delbyid.error = payload.error
            }
        },
    },
    actions: {
        getListAccountBank({ commit }, params) {
            var search = params != undefined && params.search != undefined ? params.search : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 50
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''

            commit('_setListAccountBank', {
                loading: true,
                list: [],
                meta: {},
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`partner/bank_account/list?limit=${limit}&cursor=${cursor}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('_setListAccountBank', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('_setListAccountBank', {
                    loading: false,
                    list: [],
                    error: message + err500
                })
            })
        },
        _setCreateUpdate({ commit }, params) {

            var id = params != undefined && params.id != undefined ? params.id : ''
            var bank_name = params != undefined && params.bank_name != undefined ? params.bank_name : ''
            var account_name = params != undefined && params.account_name != undefined ? params.account_name : ''
            var account_number = params != undefined && params.account_number != undefined ? params.account_number : ''
            var saldo = params != undefined && params.saldo != undefined ? params.saldo : ''

            commit('setCreateUpdate', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var action = 'create'
            var field = new FormData();
            if (id != '') {
                action = 'update'
                field.append('id', id)
            }
            field.append('bank_name', bank_name)
            field.append('account_name', account_name)
            field.append('saldo', saldo)
            field.append('account_number', account_number)

            apiClient.post(`partner/bank_account/${action}`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setCreateUpdate', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setCreateUpdate', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _setDelete({ commit }, id) {

            commit('setDelete', {
                loading: true,
                data: null,
                message: '',
                error: ''
            })

            var field = new FormData();
            field.append('id', id)

            apiClient.post(`partner/bank_account/delete`,field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setDelete', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDelete', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        accountbank(state) {
            return state.accountbank
        },
        createupdate(state) {
            return state.createupdate
        },
        delbyid(state) {
            return state.delbyid
        },
    }
}