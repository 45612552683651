import api from "./api";
import TokenService from "@/services/tokenService";

export default {
  login({ email, otp }) {
    return api.post("/partner/auth/login/otp", { email, otp, fcm: "98h32f98h32f9832hf" });
  },
  loginByOtp({ email, otp }) {
    return api.post("/partner/auth/login/otp/send", { email, otp, fcm: "98h32f98h32f9832hf" });
  },
  getProfile() {
    api.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getUserToken().access_token}`;
    return api.get("/partner/auth/profile");
  },
};
