<template>
  <div class="min-h-screen min-w-full flex md:flex-col lg:flex-row">
    <div class="wrapper-left m-6 rounded-2xl">
      <div class="lg:flex-1 m-10">
        <img src="@/assets/mobooking-logo-blue.png" width="202" alt="Logo" />
        <p class="text-body1 text-medium-grey-80 mt-4">
          Make your life more easier <br />
          with us
        </p>
      </div>
      <img
        src="@/assets/illustrations/il-login.png"
        height="685"
        alt="Illustration-Login"
      />
    </div>
    <!--tes-->

    <div class="lg:flex-1 flex flex-col justify-center items-center">
      <div class="wrapper-right">
        <div class="absolute top-10 right-10">
          <router-link
            tag="a"
            active-class=""
            class="
              inline-block
              px-5
              py-2
              bg-medium-blue
              hover:bg-blue
              text-white text-button1
              font-bold
              rounded-full
            "
            to="/form-pengajuan-merchant"
          >
            <img
              class="float-left mt-1 mr-2"
              src="@/assets/icons/ic-person-white.svg"
              alt="Icon Join"
            />
            Join Us
          </router-link>
        </div>
        <h1 class="font-bold text-dark-grey-80 text-heading3">
          👋 <br />
          Halo, <br />
          Selamat Datang!
        </h1>
        <h2 class="text-body2 pt-4 pb-10 text-medium-grey-80">
          Silakan mengisi Email dan OTP di bawah
        </h2>

        <div
          v-if="showErrorMessage !== ''"
          class="
            flex
            items-center
            justify-between
            bg-dark-red bg-opacity-40
            rounded-lg
            border-1 border-dark-red
            p-4
            mb-4
          "
          role="alert"
        >
          <span class="text-body4 text-dark-grey-80">
            {{ showErrorMessage }}
          </span>
          <img
            @click="toggleError"
            src="@/assets/icons/ic-close.svg"
            class="w-4 h-4 hover:cursor-pointer"
            alt="close"
          />
        </div>

        <div>
          <fieldset v-if="!sendotp">
            <legend class="font-bold text-dark-grey-80 text-subtitle3">
              Email
            </legend>
            <input
              class="
                text-body3 text-dark-grey-80
                w-full
                py-3
                border-b-2 border-light-grey-cc
                focus:outline-none focus:border-medium-blue
              "
              type="text"
              v-model="form.email"
              placeholder="Tulis email di sini"
              required
            />
          </fieldset>

          <fieldset class="mt-6 relative" v-if="sendotp">
            <legend class="font-bold text-dark-grey-80 text-subtitle3">
              OTP
            </legend>

            <img
              @click="handleShow"
              src="@/assets/icons/ic-eye.svg"
              class="
                cursor-pointer
                w-5
                h-5
                absolute
                z-10
                top-1/2
                transform
                -translate-y-1/2
                right-3
              "
              alt="icon-password "
            />

            <input
              type="text"
              class="
                text-body3 text-dark-grey-80
                w-full
                py-3
                z-0
                border-b-2 border-light-grey-cc
                focus:outline-none focus:border-medium-blue
              "
              :class="errors.otp && 'focus:border-dark-red'"
              v-model="form.otp"
              placeholder="Masukan kode OTP disini"
              required
            />
            <span
              class="flex mt-2 text-dark-red text-body4"
              v-if="errors.otp"
              >{{ errors.otp }}</span
            >
          </fieldset>

          <div class="flex justify-between py-4">
            
          </div>

          <button
            v-if="!sendotp"
            @click="reqOtp"
            type="button"
            :disabled="isLoading"
            class="
              w-full
              bg-medium-blue
              hover:bg-blue
              text-white text-button1
              font-bold
              py-3
              rounded-full
            "
          >
            {{isLoading ? 'Mohon tunggu...':'Dapatkan OTP'}}
          </button>

          <button
            v-if="sendotp"
            @click="doLogin"
            type="button"
            :disabled="isLoading"
            class="
              w-full
              bg-medium-blue
              hover:bg-blue
              text-white text-button1
              font-bold
              py-3
              rounded-full
            "
          >
            {{isLoading ? 'Mohon tunggu...':'Masuk'}}
          </button>

          <div class="flex justify-between mt-4">
            <router-link
              tag="a"
              active-class=""
              class="
                bg-baby-blue
                text-center
                hover:bg-blue hover:text-white
                text-medium-blue text-button1
                font-bold
                py-3
                rounded-full
                w-1/2
                mr-2
              "
              to="/privacy-policy"
              >Kebijakan Privasi
            </router-link>
            <router-link
              tag="a"
              active-class=""
              class="
                bg-baby-blue
                text-center
                hover:bg-blue hover:text-white
                text-medium-blue text-button1
                font-bold
                py-3
                rounded-full
                w-1/2
                mr-2
              "
              to="/terms-condition"
              >Syarat Pengguna
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";

export default {
  data(){
    return{
      isLoading:false,
      sendotp:false,
      showErrorMessage:'',
      form:{
        email:'',
        otp:''
      },
      errors:{
        otp:''
      }
    }
  },
  methods:{
    ...mapActions({
        loginByOtp:"auth/loginByOtp",
        login:"auth/login",
    }),
    reqOtp(){
      this.showErrorMessage = ''
      if(this.form.email !=''){
        this.isLoading=true
        this.loginByOtp(this.form).then((res)=>{
          this.isLoading=false
          if(res.data.status){
            this.sendotp = true
          }else{
            this.showErrorMessage=res.data.message
          }
          
        }).catch(({ message, response })=>{
          this.sendotp = false
          var err500 = '';
          if (response != undefined && response.status === 500) {
              err500 = ' ,' + response.data.message
          }
          this.showErrorMessage = message + err500
        })
      }
    },
    doLogin(){
      this.showErrorMessage = ''
      if(this.form.otp !='' && this.sendotp){
        this.isLoading=true
        this.login(this.form).then((res)=>{
          this.isLoading=false
          if(res.data.status){
            return this.$store.dispatch("auth/getProfile").then((response) => {
              if (response.data.data.role.name == "Lab") {
                return this.$router.push({ name: "FormTesLab" });
              } else {
                return this.$router.push({ name: "Dashboard" });
              }
            });
          }else{
            this.showErrorMessage=res.data.message
          }
        }).catch(({ message, response })=>{
          var err500 = '';
          if (response != undefined && response.status === 500) {
              err500 = ' ,' + response.data.message
          }
          this.showErrorMessage = message + err500
        })
      }
    }
  }
};
</script>

<style scoped>
.wrapper-left {
  background: linear-gradient(180deg, #f6faff 0%, rgba(246, 250, 255, 0) 100%);
}
</style>
