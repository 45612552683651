import apiClient from "@/services/api"
import tokenService from "@/services/tokenService"

export default {
    namespaced: true,
    state: {
        operasional: {
            loading: false,
            data: null,
            error: ''
        },
        booking: {
            loading: false,
            data: null,
            error: ''
        },
        setup: {
            booking: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
            operasional: {
                loading: false,
                data: null,
                message: '',
                error: ''
            },
            layanan: {
                loading: false,
                list: [],
                meta: {},
                error: '',
                feeoperasional: {
                    loading: false,
                    data: null,
                    message: '',
                    error: ''
                },
                saveupdate: {
                    loading: false,
                    data: null,
                    message: '',
                    error: ''
                },
                delete: {
                    loading: false,
                    data: null,
                    message: '',
                    error: ''
                },
                detail: {
                    loading: false,
                    data: null,
                    message: '',
                    error: ''
                },
            }
        }
    },
    mutations: {
        setOperasional(state, payload) {
            state.operasional.loading = payload.loading
            state.operasional.data = payload.data
            if (payload.error != undefined) {
                state.operasional.error = payload.error
            }
        },
        setBooking(state, payload) {
            state.booking.loading = payload.loading
            state.booking.data = payload.data
            if (payload.error != undefined) {
                state.booking.error = payload.error
            }
        },
        setSetupBooking(state, payload) {
            state.setup.booking.loading = payload.loading
            state.setup.booking.data = payload.data
            state.setup.booking.message = payload.message
            if (payload.error != undefined) {
                state.setup.booking.error = payload.error
            }
        },
        setSetupOperasional(state, payload) {
            state.setup.operasional.loading = payload.loading
            state.setup.operasional.data = payload.data
            state.setup.operasional.message = payload.message
            if (payload.error != undefined) {
                state.setup.operasional.error = payload.error
            }
        },

        // LAYANAN
        setListLayanan(state, payload) {
            state.setup.layanan.loading = payload.loading
            state.setup.layanan.list = payload.list
            state.setup.layanan.meta = payload.meta
            if (payload.error != undefined) {
                state.setup.layanan.error = payload.error
            }
        },
        setFeeOperasional(state, payload) {
            state.setup.layanan.feeoperasional.loading = payload.loading
            state.setup.layanan.feeoperasional.data = payload.data
            if (payload.error != undefined) {
                state.setup.layanan.feeoperasional.error = payload.error
            }
        },
        setSubmitLayanan(state, payload) {
            state.setup.layanan.saveupdate.loading = payload.loading
            state.setup.layanan.saveupdate.data = payload.data
            state.setup.layanan.saveupdate.message = payload.message
            if (payload.error != undefined) {
                state.setup.layanan.saveupdate.error = payload.error
            }
        },
        setDetailLayanan(state, payload) {
            state.setup.layanan.detail.loading = payload.loading
            state.setup.layanan.detail.data = payload.data
            if (payload.error != undefined) {
                state.setup.layanan.detail.error = payload.error
            }
        },
        setDeleteLayanan(state, payload) {
            state.setup.layanan.delete.loading = payload.loading
            state.setup.layanan.delete.data = payload.data
            state.setup.layanan.delete.message = payload.message
            if (payload.error != undefined) {
                state.setup.layanan.delete.error = payload.error
            }
        },
    },
    actions: {
        _getOperasional({ commit }, id) {
            // var id = params != undefined && params.id != undefined ? params.id : ''

            commit('setOperasional', {
                loading: true,
                data: null,
                error: '',
            })

            apiClient.get(`authority/merchant/setup/get-operational?partner_id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setOperasional', {
                    loading: false,
                    data: res.data.data,
                    error: '',
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setOperasional', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _getBooking({ commit }, id) {

            commit('setBooking', {
                loading: true,
                data: null,
                error: '',
            })
            apiClient.get(`authority/merchant/setup/get-booking-engine?partner_id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setBooking', {
                    loading: false,
                    data: res.data.data,
                    error: '',
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setBooking', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setSetupBooking({ commit }, params) {
            var partner_id = params != undefined && params.partner_id != undefined ? params.partner_id : ''
            var queue_type = params != undefined && params.queue_type != undefined ? params.queue_type : ''
            var hours = params != undefined && params.hours != undefined ? params.hours : []

            commit('setSetupBooking', {
                loading: true,
                data: '',
                message: '',
                error: ''
            })

            // console.log('params : ', params)
            var field = new FormData();
            field.append('partner_id', partner_id)
            field.append('queue_type', queue_type)

            if (hours.length > 0) {
                hours.forEach((v, k) => {
                    field.append(`hours[${k}]`, v)
                });
            }

            apiClient.post(`authority/merchant/setup/update-booking-engine`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                commit('setSetupBooking', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setSetupBooking', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _setSetupOperasional({ commit }, params) {
            var partner_id = params != undefined && params.partner_id != undefined ? params.partner_id : ''
            var description = params != undefined && params.description != undefined ? params.description : ''
            var availability_parking_car = params != undefined && params.availability_parking_car != undefined ? params.availability_parking_car : ''
            var availability_parking_motorcycle = params != undefined && params.availability_parking_motorcycle != undefined ? params.availability_parking_motorcycle : ''
            var note = params != undefined && params.note != undefined ? params.note : ''
            var open_all_day = params != undefined && params.open_all_day != undefined ? params.open_all_day : ''
            var line_production = params != undefined && params.line_production != undefined ? params.line_production : ''
            var estimation = params != undefined && params.estimation != undefined ? params.estimation : ''
            var monday_open = params != undefined && params.monday_open != undefined ? params.monday_open : ''
            var monday_close = params != undefined && params.monday_close != undefined ? params.monday_close : ''
            var tuesday_open = params != undefined && params.tuesday_open != undefined ? params.tuesday_open : ''
            var tuesday_close = params != undefined && params.tuesday_close != undefined ? params.tuesday_close : ''
            var wednesday_open = params != undefined && params.wednesday_open != undefined ? params.wednesday_open : ''
            var wednesday_close = params != undefined && params.wednesday_close != undefined ? params.wednesday_close : ''
            var thursday_open = params != undefined && params.thursday_open != undefined ? params.thursday_open : ''
            var thursday_close = params != undefined && params.thursday_close != undefined ? params.thursday_close : ''
            var friday_open = params != undefined && params.friday_open != undefined ? params.friday_open : ''
            var friday_close = params != undefined && params.friday_close != undefined ? params.friday_close : ''
            var saturday_open = params != undefined && params.saturday_open != undefined ? params.saturday_open : ''
            var saturday_close = params != undefined && params.saturday_close != undefined ? params.saturday_close : ''
            var sunday_open = params != undefined && params.sunday_open != undefined ? params.sunday_open : ''
            var sunday_close = params != undefined && params.sunday_close != undefined ? params.sunday_close : ''

            commit('setSetupOperasional', {
                loading: true,
                data: '',
                message: '',
                error: ''
            })

            console.log('params : ', params)
            var field = new FormData();
            field.append('partner_id', partner_id)
            field.append('description', description)
            field.append('availability_parking_car', availability_parking_car)
            field.append('availability_parking_motorcycle', availability_parking_motorcycle)
            field.append('note', note)
            field.append('open_all_day', open_all_day ? 1 : 0)
            field.append('line_production', line_production)
            field.append('estimation', estimation)
            field.append('monday_open', monday_open)
            field.append('monday_close', monday_close)
            field.append('tuesday_open', tuesday_open)
            field.append('tuesday_close', tuesday_close)
            field.append('wednesday_open', wednesday_open)
            field.append('wednesday_close', wednesday_close)
            field.append('thursday_open', thursday_open)
            field.append('thursday_close', thursday_close)
            field.append('friday_open', friday_open)
            field.append('friday_close', friday_close)
            field.append('saturday_open', saturday_open)
            field.append('saturday_close', saturday_close)
            field.append('sunday_open', sunday_open)
            field.append('sunday_close', sunday_close)

            apiClient.post(`authority/merchant/setup/update-operational`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                commit('setSetupOperasional', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: ''
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setSetupOperasional', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _getListLayanan({ commit }, params) {
            var partner_id = params != undefined && params.partner_id != undefined ? params.partner_id : ''
            var search = params != undefined && params.search != undefined ? params.search : ''
            var limit = params != undefined && params.limit != undefined ? params.limit : 10
            var cursor = params != undefined && params.meta != undefined ? params.meta : ''

            commit('setListLayanan', {
                loading: true,
                list: [],
                meta: {},
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/master/partner-service/list?partner_id=${partner_id}&limit=${limit}&cursor=${cursor}&search=${search}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setListLayanan', {
                    loading: false,
                    list: res.data.data,
                    meta: res.data.meta,
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setListLayanan', {
                    loading: false,
                    list: [],
                    error: message + err500
                })
            })
        },
        _getFeeOperasional({ commit }, id) {

            commit('setFeeOperasional', {
                loading: true,
                data: null,
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/master/partner-service/fee_operational?partner_id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setFeeOperasional', {
                    loading: false,
                    data: res.data.data,
                    error: '',
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setFeeOperasional', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setSubmitLayanan({ commit }, params) {
            var id = params != undefined && params.id != undefined ? params.id : ''
            var partner_id = params != undefined && params.partner_id != undefined ? params.partner_id : ''
            var type = params != undefined && params.type != undefined ? params.type : 'ON-SITE SERVICE'
            var name = params != undefined && params.name != undefined ? params.name : ''
            var description = params != undefined && params.description != undefined ? params.description : ''
            var estimation = params != undefined && params.estimation != undefined ? params.estimation : ''
            var basic_price = params != undefined && params.basic_price != undefined ? params.basic_price : ''
            var show_price = params != undefined && params.show_price != undefined ? params.show_price : ''
            var can_refund = params != undefined && params.can_refund != undefined ? params.can_refund : ''
            var fee_refund = params != undefined && params.fee_refund != undefined ? params.fee_refund : ''
            var fee_refund_type = params != undefined && params.fee_refund_type != undefined ? params.fee_refund_type : ''
            var booking_rules = params != undefined && params.booking_rules != undefined ? params.booking_rules : []
            var fee_booking_down_payment_type = params != undefined && params.fee_booking_down_payment_type != undefined ? params.fee_booking_down_payment_type : ''
            var fee_booking_down_payment = params != undefined && params.fee_booking_down_payment != undefined ? params.fee_booking_down_payment : ''
            var cod_price_gap = params != undefined && params.cod_price_gap != undefined ? params.cod_price_gap : ''


            commit('setSubmitLayanan', {
                loading: true,
                data: '',
                message: '',
                error: ''
            })

            var action = 'create'
            var field = new FormData();
            if (id != '') {
                action = 'update'
                field.append('id', id)
            }
            field.append('partner_id', partner_id)
            field.append('type', type === 'layanan' ? 'ON-SITE SERVICE' : 'HOME SERVICE')
            field.append('name', name)
            field.append('description', description)
            field.append('estimation', estimation)
            field.append('basic_price', basic_price)
            field.append('show_price', show_price)
            field.append('can_refund', can_refund)
            field.append('fee_refund', fee_refund)
            field.append('fee_refund_type', fee_refund_type)

            booking_rules.forEach(bv => {
                field.append('booking_rules[]', bv)
            });

            field.append('fee_booking_down_payment_type', fee_booking_down_payment_type)
            field.append('fee_booking_down_payment', fee_booking_down_payment)
            field.append('cod_price_gap', cod_price_gap)

            apiClient.post(`authority/master/partner-service/${action}`, field, {
                headers: {
                    "Authorization": `Bearer ${tokenService.getUserToken().access_token}`,
                    'Content-Type': 'multipart/form-data'
                },
            }).then((res) => {

                var err = ''
                if (!res.data.status) {
                    err = res.data.message
                }
                commit('setSubmitLayanan', {
                    loading: false,
                    data: res.data.data,
                    message: '',
                    error: err
                })
            }).catch(({ message, response }) => {
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setSubmitLayanan', {
                    loading: false,
                    message: '',
                    data: null,
                    error: message + err500
                })
            })
        },
        _getDetailLayanan({ commit }, id) {

            commit('setDetailLayanan', {
                loading: true,
                data: null,
                error: '',
            })

            // cursor = cursor.next_cursor != undefined ? cursor.next_cursor : ''
            apiClient.get(`authority/master/partner-service/detail?id=${id}`, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setDetailLayanan', {
                    loading: false,
                    data: res.data.data,
                    error: '',
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDetailLayanan', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
        _setDeleteLayanan({ commit }, id) {

            commit('setDeleteLayanan', {
                loading: true,
                data: null,
                error: '',
            })

            var field = new FormData();
            field.append('id', id)
            apiClient.post(`authority/master/partner-service/delete`, field, {
                headers: { "Authorization": `Bearer ${tokenService.getUserToken().access_token}` }
            }).then((res) => {

                commit('setDeleteLayanan', {
                    loading: false,
                    data: res.data.data,
                    error: '',
                })
            }).catch(({ message, response }) => {
                // console.log('catch response :', response)
                var err500 = '';
                if (response != undefined && response.status === 500) {
                    err500 = ' ,' + response.data.message
                }
                commit('setDeleteLayanan', {
                    loading: false,
                    data: null,
                    error: message + err500
                })
            })
        },
    },
    getters: {
        operasional(state) {
            return state.operasional
        },
        booking(state) {
            return state.booking
        },
        setup(state) {
            return state.setup
        },
        feeoperasional(state) {
            return state.setup.layanan.feeoperasional
        },
        saveupdate(state) {
            return state.setup.layanan.saveupdate
        },
        detaillayanan(state) {
            return state.setup.layanan.detail
        },
        deletelayanan(state) {
            return state.setup.layanan.delete
        },
    }
}